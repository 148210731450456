// src/pages/MainPage.js
import React, { useContext, useState } from "react";
import { DataContext } from "../context/DataContext";
import "./MainPage.css";
import Header from "../sections/Header";
import OurClient from "../sections/OurClient";
import JobDescription from "../sections/JobDescription";
import RequirementsAndResponsibilities from "../sections/ReqsAndResps";
import ThankYou from "../sections/ThankYou";
import HowToProceed from "../sections/HowToProceed";
import getFromData from "../helperFunctions/getFromData";
import NdaSection from "../sections/SignNda";
import { SettingsContext } from "../context/SettingsContext";
import Popup from "../components/FeedbackPopup"; // Import the Popup component

function MainPage() {
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  return (
    <div className="flex graphite-bg h-fit justify-center items-center flex-col">
      <div className="card">
        <Header />
        <OurClient />
        <JobDescription />
        <RequirementsAndResponsibilities />
        <ThankYou />
        {settings.confidential ? (
          <NdaSection documentHash={getFromData("documentHash", data)} />
        ) : (
          <HowToProceed
            documentHash={getFromData("documentHash", data)}
            accepted={getFromData("Accepted", data)}
          />
        )}
      </div>

    
      <div className="flex text-white mt-4 mb-24 items-center justify-end w-full max-w-screen-lg">

      {!settings.feedbackGiven ?
        <p onClick={togglePopup} className="cursor-pointer">
         <span class="pulsate-emoji">👉 Give us feedback on this document!</span> 
        </p> :
<p>Thank you for your feedback!</p>
}
      </div>


      {showPopup && <Popup onClose={togglePopup} />}
    </div>
  );
}

export default MainPage;